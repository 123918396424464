import React, { useRef, useState, useEffect } from 'react'
import firebase from "gatsby-plugin-firebase"
import EmailCallback from '../../svg/email-back.svg'
import ChevronRight from '../../svg/chevron-right-blue.svg'
import ChevronLeft from '../../svg/chevron-left-blue.svg'
import Close from '../../svg/close.svg'
import Loading from '../../svg/loading.svg';
import * as chatHelpers from '../../helpers/chatroom-helper'
import { wait } from '../../helpers/timing'

const db = firebase.firestore();
let callbackTimeout = false;

export default function ChatroomCallback({status, close, callbackActive, connected}) {
  const [minimum, setMinimum] = useState(3);
  const [maximum, setMaximum] = useState(9);
  const [number, setNumber] = useState(3);
  const [hasSubmit, setHasSubmit] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [valMsg, setValMsg] = useState(false);
  const [valActive, setValActive] = useState(false);
  const [midEmailCapture, setMidEmailCapture] = useState('');
  const emailInputRef = useRef(null);
  const newsletterCheckboxRef = useRef(null);
  const [callbackState, setCallbackState] = useState('countdown');
  let callbackStartup = false;

  useEffect(() => {
    if (!callbackStartup && callbackActive && !connected && callbackState == 'countdown') {
      setTimeout(callbackTimeout = () => {
        setCallbackState('active')
        firebase.analytics().logEvent('Callback - Callback triggered');
      }, 3000);
      callbackStartup = true;
    }

    if (connected && !callbackState.includes('remove')) {
      clearTimeout(callbackTimeout)
      setCallbackState('ready')
    }

    if (status == 'chat-active' && emailInputRef.current && emailInputRef.current.value.length > 0 && !hasSubmit) {
      setMidEmailCapture(emailInputRef.current.value);
      firebase.analytics().logEvent('Callback - Mid email capture');
      setCallbackState('mid-interaction')
    } else if  (status == 'chat-active' && callbackState == 'active') {
      setCallbackState('ready')
      // setTimeout(() => closeCallback(), 400);
    }

    return () => clearTimeout(callbackTimeout);
  }, [callbackState, status, connected])



  const incCount = (direction) => {
    if (direction == 'up') {
      setNumber(number !== maximum ? number + 1 : number)
    } else {
      setNumber(number !== minimum ? number - 1 : number)
    }
  }

  const closeCallback = () => {
    setCallbackState(callbackState + ' remove');
    setTimeout(() => close(), 400);
  }

  const passesValidation = (email) => {
    let passes = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    if (email.length === 0) {
      setValMsg('Whoops, this looks empty - got an email?')
      firebase.analytics().logEvent('Callback - Empty input validation');
      setValActive(true);
      setTimeout(() => setValActive(false), 4000);
      return false;
    } else if (!passes) {
      setValMsg("This email doesn't look right")
      firebase.analytics().logEvent('Callback - Wrong email validation');
      setValActive(true);
      setTimeout(() => setValActive(false), 4000);
      return false;
    } else if (passes) {
      return true;
    }
  }

  const handleSubmit = async (e, midinput) => {
    e.preventDefault();
    
    let email = emailInputRef.current.value;
    let newsLetter = newsletterCheckboxRef.current.value == 'on' ? true : false;

    if (passesValidation(email)) {
      setSubmitting(true);

      let callbackObj = {
        email: email,
        number: number,
        waitingOnA: chatHelpers.otherType(),
        newsLetter: newsLetter,
        time: firebase.firestore.Timestamp.fromDate(new Date())
      }

      firebase.analytics().logEvent('Callback - Submit event', {
        params: callbackObj
      });

      if (midinput) {
        callbackObj = {
          email: email,
          newsLetter: true,
        }
      }

      await db.collection('callback').add(callbackObj)
      await wait(2);
      setHasSubmit(true);
      setCallbackState(midinput ? 'mid-interaction-submitted' : 'thank-you')
    }
  }

  const statusReadyMidThank = () => {
    if (
      callbackState.includes('ready') || 
      callbackState.includes('mid-interaction') || 
      callbackState.includes('mid-interaction-submitted') || 
      callbackState.includes('thank-you')
    ) {
      return true
    } else {
      return false
    }
  }

  const readyText = () => {
    if (callbackState.includes('ready')) {
      return (
        <p className={`chatroom-callback__ready-text`}>
          A {chatHelpers.otherType()} just joined the chat, say hello!
        </p>
      )
    } else if (callbackState.includes('thank-you')) {
      return (
        <p className={`chatroom-callback__ready-text`}>
          Got it! 👍 you can leave now if you want!<br/>
          Just keep an eye on your email 👀<br/>
          or hang around and wait for someone to join! ⌚
        </p>
      )
    } else if (callbackState.includes('mid-interaction-submitted')) {
      return (
        <p className={`chatroom-callback__ready-text`}>Thanks for subscribing, enjoy your chat!</p>
      )
    } else if (callbackState.includes('mid-interaction')) {
      return (
        <>
          <p className={`chatroom-callback__ready-text`}>A {chatHelpers.otherType()} just joined! Would you like to join the newsletter instead?</p>
          <form onSubmit={(e) => handleSubmit(e, true)} noValidate="novalidate">
            <input 
              noValidate="novalidate"
              type="email" 
              className={`chatroom-callback__email-input`}
              ref={emailInputRef}  
              defaultValue={midEmailCapture}
              placeholder={`Your email`}
            />
            <button 
              type="submit"
              className={`chatroom-callback__submit cta ${submitting ? 'submitting' : ''}`}
            >
              Submit <Loading/>
            </button>
          </form>
          <p className={`chatroom-callback__email-validation ${valActive ? 'active' : ''}`}>{valMsg}</p>
        </>
      )
    }
  }

  return (
    <div className={`chatroom-callback ${callbackState}`}>
      {statusReadyMidThank() ?
        <button 
          className="chatroom-callback__close"
          onClick={closeCallback}
        >
          <Close/>
        </button>
      : null}
      <div className={`chatroom-callback__ready ${statusReadyMidThank() ? 'active' : ''}`}>
        {readyText()}
      </div>
      <div className="chatroom-callback__content">
        <EmailCallback className="chatroom-callback__image"/>
        <div className={`chatroom-callback__body ${callbackState}`}>
          <h3 className="chatroom-callback__title">Tired of waiting?</h3>
          <h4 className="chatroom-callback__subtitle">We can email you back!</h4>
          <span>Email me back when there are at least</span>
          <button 
            className={`chatroom-callback__count-button ${number == minimum ? 'deactive' : ''}`}
            onClick={() => {
              firebase.analytics().logEvent('Callback - Decrement button used');
              incCount('down');
            }}
          >
            <ChevronLeft/>
          </button>
          <p className="chatroom-callback__number">{number}</p>
          <button 
            className={`chatroom-callback__count-button ${number == maximum ? 'deactive' : ''}`}
            onClick={() => {
              firebase.analytics().logEvent('Callback - Increment button used');
              incCount('up');
            }}
          >
            <ChevronRight/>
          </button>
          <span>{chatHelpers.otherType()}s waiting to chat.</span>
          <form onSubmit={handleSubmit} noValidate="novalidate">
            <input 
              type="checkbox" 
              id="newsletter" 
              className="chatroom-callback__checkbox"
              name="newsletter" 
              ref={newsletterCheckboxRef}  
              onClick={() => firebase.analytics().logEvent('Callback - Newsletter checkbox')}
              defaultChecked
            />
            <label 
              htmlFor="newsletter"
              className="chatroom-callback__checkbox-label"
            >
              Would you like to signup for the Helpful Chat newsletter as well?
            </label>
            <input 
              noValidate="novalidate"
              type="email" 
              className={`chatroom-callback__email-input`}
              ref={emailInputRef}  
              placeholder={`Your email`}
              disabled={submitting}
            />
            <button 
              type="submit"
              disabled={hasSubmit}
              className={`chatroom-callback__submit cta ${submitting ? 'submitting' : ''}`}
            >
              Submit <Loading/>
            </button>
          </form>
          <p className={`chatroom-callback__email-validation ${valActive ? 'active' : ''}`}>{valMsg}</p>
        </div>
      </div>
    </div>
  ) 
}