import { Howl } from 'howler';

export function playOpenSound() {
  const openSound  = new Howl({src: './sounds/roomOpen.mp3'});
  openSound.play();
}

export function playMsgSound() {
  const msgSound  = new Howl({src: './sounds/msg.mp3'});
  msgSound.play();
}

export function playCloseSound() {
  const closeSound  = new Howl({src: './sounds/roomClose.mp3'});
  closeSound.play();
}