import React, { useState, useEffect } from 'react'
import { parseHTML } from '../../helpers/parseHTML';
import ListenerIcon from '../../svg/listener-icon.svg';
import { useSelector } from 'react-redux';
import Modal from 'react-modal'
import LobbyModal from './lobby-modal'
import { useStaticQuery, graphql } from 'gatsby';
import SEO from './../layouts/seo';
import ReactTooltip from "react-tooltip";
import firebase from "gatsby-plugin-firebase";
 
Modal.setAppElement('#___gatsby');


export default function Lobby({ setUser }) {
  let lobbyLoaded = false;
  const data = componentData();
  const { firebaseCounts, user } = useSelector(state => state.siteState);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [selectedType, setselectedType] = useState('');
  const [exitLobby, setExitLobby] = useState(false);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (!lobbyLoaded) {
      setTimeout(() => setHover(true), 700);
      lobbyLoaded = true;
    }
  })

  function getCountsMarkup(counts, type) {
    let listeners = counts['listener-waiting'];
    let talkers = counts['talker-waiting'];
    if (listeners > 0 && type == 'listener') {
      return (
        <span className={`lobby__counts`}>
          {listeners}
        </span> 
      )
    } else if (talkers > 0 && type == 'talker') {
      return (
        <span className={`lobby__counts`}>
          {talkers}
        </span>
      ) 
    } else {
      return null;
    }
  }

  function openModal() {
    setIsOpen(true);
    setTimeout(() => setModalActive(true), 50);
    firebase.analytics().logEvent('Lobby - Terms modal open');
  }

  function checkUser(type) {
    setselectedType(type);
    firebase.analytics().logEvent(`Lobby - ${type} CTA click`);
    if (user !== null && user.agreedToTerms) {
      setExitLobby(true);
      setTimeout(() => setUser(type), 1200);
    } else {
      openModal();
    }
  }
 
  function submitModal() {
    setModalActive(false)
    setTimeout(() => setIsOpen(false), 400);
    if (selectedType !== '') {
      setExitLobby(true);
      setTimeout(() => setUser(selectedType), 1200);
      firebase.analytics().logEvent('Lobby - Terms modal accepted terms');
    }
  }
 
  function closeModal(){
    setModalActive(false)
    setTimeout(() => setIsOpen(false), 400);
    firebase.analytics().logEvent('Lobby - Terms modal closed');
  }

  return (
    <section className={`lobby ${exitLobby ? 'exit' : ''}`}>
      <SEO title={'Lobby'} description={'Room'}/> 
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className={`${modalActive ? 'active' : ''}`}
        overlayClassName={`${modalActive ? 'active' : ''}`}
      >
        <LobbyModal 
          closeModal={closeModal}
          submitModal={submitModal}
        />
      </Modal>
      <div className={`lobby-container`}>
        <h1 className={`lobby__title`}>{data.introLine}</h1>
        <div className="lobby__button-container">
          {data.lobbyButtons.map((button, index) => {
            let type = button.title.toLowerCase();
            let image = type == 'listener' ? `../images/illustrations/listener.svg` : `../images/illustrations/talker.svg`;
            return (
              <div 
                key={index}
                tabIndex={0}
                onClick={() => checkUser(type)}
                className={`
                  lobby__button lobby__button-${type} ${selectedType == type ? 'selected' : ''}
                  ${hover ? 'hoverable' : ''}
                `}
              >
                {firebaseCounts !== false && firebaseCounts !== undefined ? 
                  getCountsMarkup(firebaseCounts, type)
                : null}
                <h2 className={`lobby__button-title`}>{button.title}</h2>
                <div 
                  className="lobby__button-image"
                  style={{
                    WebkitMaskImage: `url(${image})`,
                    backgroundImage: `url(../images/svg-texture.png), url(${image})`,
                  }}
                ></div>
                <div className={`lobby__button-text`}>{parseHTML(button.text)}</div>
                <img className={`lobby__button-texture`} src="../images/svg-texture.png" alt=""/>
                <img className={`lobby__button-texture`} src="../images/svg-texture.png" alt=""/>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

const componentData = () => {  
  const { data } = useStaticQuery(graphql`
    query {
      data: datoCmsLobby {
        introLine
        lobbyButtons {
          text
          title
          image {
            alt
            url
          }
        }
      }
    }
  `)
  return data
}