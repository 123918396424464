import firebase from "gatsby-plugin-firebase";

const db = firebase.firestore();

export async function setUserTerms(uid) {
  return new Promise(async (resolve, reject) => {
    try {
      await db.collection("users").doc(uid).set({
        agreedToTerms: true,
      })
      resolve(true)
    } catch (e) {
      reject(e)
    }
  });
}