import React, { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import firebase from "gatsby-plugin-firebase"
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import * as chatHelpers from '../../helpers/chatroom-helper'
import Emotion from '../../svg/emotion.svg'
import "wicg-inert";

export default function ChatroomInput() {
  const dispatch = useDispatch();
  const chatroomState = useSelector(state => state.chatroomState);
  const [connectionState, setConnectionState] = useState('connecting');
  const inputRef = useRef(null);
  const bodyRef = useRef(null);
  const [emojiMenu, setEmojiMenu] = useState(false);
  let startupTasks = false;
  const { roomData } = chatroomState;
  const {active, open, listener, talker} = roomData;

  const getConnectionState = () => {
    if (active && !open) {
      setConnectionState("chat-active")
    } else if (active && open) {
      setConnectionState("waiting")
    } else if (!active && !open && listener && talker) {
      setConnectionState("user-left")
    } else {
      setConnectionState("connecting")
    }
  }

  useEffect(() => {
    getConnectionState()
    if (!startupTasks) {
      inertEmojis()
      startupTasks = true;
    }
  })

  function inertEmojis() {
    document.querySelector('.emoji-mart').setAttribute('inert', 'true');
    
  }

  function addEmoji(emoji) {
    inputRef.current.value = inputRef.current.value + emoji.native;
    inputRef.current.focus();
    setEmojiMenu(false);
  }

  function openEmojiMenu() {
    if (emojiMenu) {
      setEmojiMenu(false)
      document.querySelector('.emoji-mart').setAttribute('inert', 'true');
    } else {
      document.querySelector('.emoji-mart').removeAttribute('inert');
      setEmojiMenu(true)
      setTimeout(() => {
        if (document.getElementById('emoji-mart-search-1') !== null) {
          document.getElementById('emoji-mart-search-1').focus();
        }
      }, 100);
    }
  }

  return (
    <>
      {chatroomState.chatrowData[`${chatHelpers.otherType()}Typing`] ?
        <p>{chatHelpers.otherType()} is typing</p>
        : 
        ''
      }
      <form 
        onSubmit={(e) => chatHelpers.handleSubmit(e, dispatch, inputRef.current)}
        className={`chatroom__form ${connectionState}`}
      >
        <textarea 
          type="text" 
          className={`chatroom__form-input`}
          placeholder={`Type a message`}
          ref={inputRef}
          onKeyDown={(e) => e.keyCode === 13 ? chatHelpers.handleSubmit(e, dispatch, inputRef.current) : null}
          onChange={(e) => chatHelpers.setTyping(e, inputRef.current)}
        />
        <div className="chatroom__form-right">
          <Emotion 
            tabIndex={0}
            className={`chatroom__form-emoji-button`}
            onKeyDown={(e) => e.keyCode === 13 ? openEmojiMenu() : null}
            onClick={() => {
              firebase.analytics().logEvent('Chatroom - Emoji menu button clicked');
              openEmojiMenu()
            }}
          />
          <div 
            className={`chatroom__form-emoji-wrapper ${emojiMenu ? 'active' : ''}`}
            tabIndex={-1}
          >
            <Picker 
              tabIndex={-1}
              set='twitter' 
              onSelect={emoji => addEmoji(emoji)}
            />
          </div>
          <input 
            type="submit"
            value="Send"
            className={`chatroom__form-submit`}
          />
        </div>
      </form>
    </>
  )
}