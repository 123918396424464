import React, { useEffect, useState } from 'react'
import firebase from "gatsby-plugin-firebase"
import Layout from "../components/layouts/layout"
import SEO from "../components/layouts/seo"
import Lobby from '../components/lobby/lobby';
import Chatroom from '../components/chatroom/chatroom';
import Nav from '../components/global/nav';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../state/actions/actions-chatroom';
import { Helmet } from 'react-helmet';

export default function Room(props) {
  const { transitionStatus } = props;
  const dispatch = useDispatch();
  const uid = useSelector(state => state.siteState.firebaseUid);
  const chatroomState = useSelector(state => state.chatroomState);
  const [userID, setuserID] = useState(uid ? uid : false);
  let hasUID = userID == false ? false : true;

  useEffect(() => {
    // If still waiting for the UID (directly landed on /chatroom, dont proceed until you get it)
    if (userID == false && uid !== false && hasUID == false) {
      setuserID(uid);
      hasUID = true;
    }
  })

  function setUser(userType) {
    firebase.analytics().logEvent(`Chatroom - starting room - ${userType}`)
    dispatch(actions.setUserType(userType))
    dispatch(actions.setRoomStep('searching'))
  }

  function roomSteps() {
    switch (chatroomState.roomStep) {
      case 'lobby':
        if (userID !== false) {
          return <Lobby setUser={setUser}/>
        } 
        break;
      case 'searching':
      case 'chatactive':
        return <Chatroom/> 
      case 'postchat':
        break;
    }
  }

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://helpful.chat"
              },{
                "@type": "ListItem",
                "position": 2,
                "name": "Chatroom",
                "item": "https://helpful.chat/chatroom"
              }]
            }
          `}
        </script>
      </Helmet>
      <Nav extraClass={'no-nav'}/>
      <Layout transitionStatus={transitionStatus}>
        <SEO 
          title={'Chatroom'} 
          description={`Lobby and chatrooms for Helpful Chat`}
        /> 
        {roomSteps()}
      </Layout>
    </>
  )
}