import React, { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-invisible-recaptcha';
import Close from '../../svg/close.svg';
import Loading from '../../svg/loading.svg';
import { useStaticQuery, graphql } from 'gatsby';
import { parseHTML } from '../../helpers/parseHTML';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { setUserTerms } from '../../helpers/lobby-helper';

export default function lobbyModal({closeModal, submitModal}) {
  const uid = useSelector(state => state.siteState.firebaseUid);
  const data = componentData();
  const recaptchaRef = useRef(null);
  const recaptchaPublicKey = "6LcE0PwUAAAAADl1QRIHyA3Y38VbbElWul47jKtg";
  const recapCheckApi = `https://us-central1-helpful-chat.cloudfunctions.net/checkRecaptcha`;
  const [submitting, setSubmitting] = useState(false);
  
  async function recaptchaResolved() {
    setSubmitting(true)
    let recapResponse = recaptchaRef.current.getResponse();
    if (recapResponse !== null) {
      // API check 
      const res = await axios.post(recapCheckApi, {userkey: recapResponse}); 
      if (res.data.success) {
        setSubmitting(false);
        await setUserTerms(uid);
        submitModal()
      }
    }
  }

  return (
    <div className="lobby-modal">
      <button onClick={closeModal} className={`lobby-modal__close`}>
        <Close/>
      </button>
      <h2 className={`lobby-modal__title`}>
        {parseHTML(data.modalTitle)}
      </h2>
      <div className="lobby-modal__copy">
        <div className={`lobby-modal__copy-disclaimer`}>
          {parseHTML(data.modalDisclaimer)}
        </div>
        <h3 className={`lobby-modal__copy-title`}>
          {data.modalSubtitle}
        </h3>
        <ul className={`lobby-modal__copy-list`}>
          {data.modalRules.map((rule, i) => {
            return <li key={`rule-${i}`}>{rule.text}</li>
          })}
        </ul>
      </div>
      <ReCAPTCHA
        className={`lobby-modal__recaptcha`}
        sitekey={recaptchaPublicKey}
        ref={recaptchaRef}
        onResolved={recaptchaResolved}
      />
      <button 
        className={`lobby-modal__submit cta ${submitting ? 'submitting' : ''}`}
        onClick={() => recaptchaRef.current.execute()}
        disabled={submitting}
      >
        {data.modalCta}
        {submitting ? <Loading/> : null}
      </button>
      <div className={`lobby-modal__terms`}>
        {parseHTML(data.modalPolicyText)}
      </div>
    </div>
  )
}

const componentData = () => {  
  const { data } = useStaticQuery(
    graphql`
      query {
        data: datoCmsLobby {
          modalTitle
          modalDisclaimer
          modalSubtitle
          modalRules {
            text
          }
          modalCta
          modalPolicyText
        }
      }
    `
  )
  return data
}