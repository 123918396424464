import firebase from "gatsby-plugin-firebase"
import favico from 'favico.js-slevomat'
import * as sounds from './sounds-helper'
import * as actions from '../state/actions/actions-chatroom'
import { setSubscriptions } from './../state/actions/actions-chatroom';
import { store } from './../state/reduxWrapper';
import animateScrollTo from 'animated-scroll-to';

// FB refs
const db = firebase.firestore();
const rtdb = firebase.database();
const roomRef = db.collection('rooms');
const chatrowsRef = db.collection('chatrows');
const getState = () =>  store.getState().chatroomState; 
const getRealtimeKey = () => store.getState().siteState.realtimeKey;

export function findRoomOrStart(dispatch) {
  const state = getState();

  roomRef
    .where("open", "==", true)
    .where("active", "==", true)
    .where("talker", "==", state.userType == 'talker' ? false : true)
    .where("listener", "==", state.userType == 'listener' ? false : true)
    .get().then(async (querySnapshot) => {
      let rooms = [];
      querySnapshot.forEach(doc => rooms.push(doc.id));
      // If no room match - start room
      if (rooms.length == 0) {
        startRoom(dispatch);
      // Else join room
      } else {
        let lastRoom = rooms[0];
        
        dispatch(actions.setRoomID(lastRoom));
        watchRoom(dispatch);
        setRoomCommence(dispatch);
      }
    })

  handleVisibility(dispatch);
}

export async function startRoom(dispatch) {
  const state = getState();

  let room = await roomRef.add({
    open: true,
    active: true,
    timeMade: firebase.firestore.Timestamp.fromDate(new Date()),
    listener: state.userType == 'listener' ? true : false,
    talker: state.userType == 'talker' ? true : false,
  })

  await chatrowsRef.doc(room.id).set({
    chatRows: [],
    listenerTyping: false,
    talkerTyping: false,
  })

  firebase.analytics().logEvent(`Chatroom - started fresh chatroom - ${state.userType}`)

  dispatch(actions.setRoomID(room.id))
  dispatch(actions.setUserWaiting(true))

  watchRoom(dispatch);
}

export function handleVisibility(dispatch) {
  dispatch(actions.setFavico(new favico({animation:'none'})))
  document.addEventListener("visibilitychange", () => {
    const state = getState();
    if (document.visibilityState && state.favico !== null) {
      dispatch(actions.setFavicoCount(0))
      if (state.favico !== null) state.favico.badge(0);
    }
  })
}

export async function watchRoom(dispatch) {
  const state = getState();
  const realtimeKey = getRealtimeKey();
  let subscriptions = [];

  rtdb.ref(`users/${realtimeKey}`).update({
    roomID: state.roomId,
    userType: state.userType
  });

  await roomRef.doc(state.roomId).update({[state.userType]: true});

  let roomSubscription = roomRef.doc(state.roomId).onSnapshot(async roomDoc => {
    let docData = roomDoc.data();
    if (await checkRoomActive(dispatch, docData)) {
      await setRoomCommence(dispatch)
      newChatRow(dispatch, docData.chatRows)
    }
  });

  let chatrowSubscription = chatrowsRef.doc(state.roomId).onSnapshot(async chatrowDoc => {
    let docData = chatrowDoc.data();
    newChatRow(dispatch, docData.chatRows)
    dispatch(actions.setChatRowData(docData));
  });

  subscriptions.push(roomSubscription);
  subscriptions.push(chatrowSubscription);
  dispatch(setSubscriptions(subscriptions));
}



export async function setRoomCommence(dispatch) {
  const state = getState();
  if (state.roomData.open && state.roomData.listener && state.roomData.talker) {
    sounds.playOpenSound();
    dispatch(actions.setRoomStep('chatactive'))
    await roomRef.doc(state.roomId).update({open: false})
    firebase.analytics().logEvent(`Chatroom - chatroom active`)
  } else {
    return
  }
}


export function checkRoomActive(dispatch, roomData) {
  const state = getState();
  // Room gets deleted or something happens to data
  if (roomData === undefined) {
    sounds.playCloseSound();
    state.subscriptions.forEach(sub => sub());
    dispatch(actions.setRoomData({
      ...state.roomData,
      active: false
    }))
    return false
  }

  // User leaves chat or something like that
  if (!roomData.active) {
    sounds.playCloseSound();
    state.subscriptions.forEach(sub => sub());
    dispatch(actions.setRoomData(roomData))
    return false
  // Otherwise continue
  } else {
    dispatch(actions.setRoomData(roomData))
    return true
  }
}

export function newChatRow(dispatch, dataRows) {
  const state = getState();
  if (dataRows !== undefined) {
    if (dataRows.length > state.chatRows.length) {
      if (document.visibilityState == 'hidden') {
        if (state.favicoCount < 1) sounds.playMsgSound();
        let favicoCount = state.favicoCount + 1;
        dispatch(actions.setFavicoCount(favicoCount));
        if (state.favico !== null) state.favico.badge(favicoCount);
        if (state.favico !== null) state.favico.badge(favicoCount);

      }
      dispatch(actions.setChatRows(dataRows !== undefined ? dataRows : []))
    }

    // Scroll chatroom body down
    setTimeout(() => {
      var chatBodyEl = document.getElementById("chatroom__body");
      // chatBodyEl.scrollTop = chatBodyEl.scrollHeight;
      if (chatBodyEl) {
        animateScrollTo(chatBodyEl.scrollHeight, {
          speed: 2000,
          easing: (t) => { return t * (2 - t) },
          elementToScroll: chatBodyEl
        })
      }
    }, 100);
  }
}


export async function handleSubmit(e, dispatch, inputRef) {
  e.preventDefault();
  const state = getState();
  let chatRows = state.chatRows.slice();

  let chatRow = {
    time: firebase.firestore.Timestamp.fromDate(new Date()),
    text: inputRef.value,
    status: 'recieved',
    user: state.userName + ` - ${state.userType}`,
    userType: state.userType
  }

  chatRows.push(chatRow)

  inputRef.value = "";
  inputRef.focus();

  dispatch(actions.setChatRows(chatRows))
  
  await setTyping(null, inputRef)
  await chatrowsRef.doc(state.roomId).update({
    chatRows: chatRows
  })
}

export async function setTyping(e, inputRef) {
  const state = getState();
  
  if (inputRef.value.length > 0 && state.chatRowData[`${state.userType}Typing`] == false) {
    await chatrowsRef.doc(state.roomId).update({
      [`${state.userType}Typing`]: true
    })
  } else if (inputRef.value.length == 0 && state.chatRowData[`${state.userType}Typing`] == true) {
    await chatrowsRef.doc(state.roomId).update({
      [`${state.userType}Typing`]: false
    })
  }
}

export async function leaveRoom(dispatch, once) {
  const state = getState();

  // Check if room has data
  if (Object.keys(state.roomData).length > 0) {
    state.subscriptions.forEach(sub => sub());
    
    if (state.roomData.active !== false) {
      await roomRef.doc(state.roomId).update({
        active: false
      })
    }

    setUser(dispatch)
  } else {
    // Checks for room build after 2 secs just incase one was in the process of building
    setTimeout(() => {if (!once) leaveRoom(dispatch, true)}, 2000);
  }
}

export function setUser(dispatch) {
  const state = getState();
  
  firebase.analytics().logEvent(`Chatroom - leaving room - ${state.userType}`)
  dispatch(actions.setInitialState())
}

export function otherType() {
  const state = getState();
  return state.userType == 'listener' ? 'talker' : 'listener';
}