import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SEO from './../layouts/seo';
import * as chatHelpers from '../../helpers/chatroom-helper'
import Background from '../../svg/homepage-background.svg';
import ChatroomBody from './chatroom-body';
import ChatroomHeader from './chatroom-header';
import ChatroomForm from './chatroom-form';
import { use100vh } from 'react-div-100vh'

let startupTasks = false;

export default function Chatroom() {
  const height = use100vh()
  const dispatch = useDispatch();
  const { subscriptions } = useSelector(state => state.chatroomState);

  useEffect(() => {
    if (!startupTasks) {
      chatHelpers.findRoomOrStart(dispatch)
      startupTasks = true;
    }
    return () => {
      chatHelpers.leaveRoom(dispatch)
      startupTasks = false;
    }
  }, [])

  return (
    <div className="inner-container">
      <div className={`chatroom`} style={{height: height - 70}}>
        <SEO title={'Chatroom'} description={'Room'}/> 
        <ChatroomHeader/>
        <ChatroomBody/>
        <ChatroomForm/>
      </div>
    </div>
  )
}