import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import firebase from "gatsby-plugin-firebase";
import Close from '../../svg/close.svg'
import AnimatedLink from './../global/animatedLink';
import * as chatHelpers from '../../helpers/chatroom-helper';
import { useDispatch } from 'react-redux';


export default function ChatroomPostchat() {
  const [feedbackStep, setFeedbackStep] = useState(0);
  const { postChatQuestions } = postChatData();
  const [postchatActive, setpostchatActive] = useState(false);
  const [questionsActive, setquestionsActive] = useState(false);
  const [closeActive, setcloseActive] = useState(false);
  const dispatch = useDispatch();
  let postchatLoaded = false;

  useEffect(() => {
    if (!postchatLoaded) {
      if (!postChatQuestions[postChatQuestions.length - 1].thankyou) {
        postChatQuestions.push({
          thankyou: true,
          question: "Thank you for sharing your opinion!",
          options: []
        })
      }
      postchatLoaded = true;
      setTimeout(() => {
        setpostchatActive(true)
        setquestionsActive(true)
      }, 1500);
      setTimeout(() => setcloseActive(true), 2500);
    }
  }, []);

  const handleSubmit = (question, answer) => {
    firebase.analytics().logEvent('Postchat - survey answer', {
      question: question,
      answer: answer
    })

    if (feedbackStep < postChatQuestions.length - 1) {
      nextStep()
    }
  }

  const nextStep = () => {
    setquestionsActive(false)
    setTimeout(() => {
      setFeedbackStep(feedbackStep + 1);
      setTimeout(() => setquestionsActive(true), 100);
    }, 800);
  }

  const closePostchat = () => {
    setpostchatActive(false)
    setTimeout(() => setcloseActive(false), 500);
  }


  return (
    <>
      <button 
        className={`chatroom-postchat__options-button ${!postchatActive ? 'active' : null}`}
        onClick={() => {
          setpostchatActive(true);
          firebase.analytics().logEvent('Postchat - survey answer');
        }}
      >
        Post chat options
      </button>
      <div className={`chatroom-postchat ${postchatActive ? 'active' : null}`}>
        <button 
          className={`chatroom-postchat__close ${closeActive ? 'active' : null}`}
          onClick={closePostchat}
        >
          <Close/>
        </button>
        <div className={`chatroom-postchat__questions ${questionsActive ? 'active' : null}`}>
          <p className={`chatroom-postchat__question`}>{postChatQuestions[feedbackStep].question}</p>
          <div className="chatroom-postchat__options">
            {postChatQuestions[feedbackStep].options.map((option, index) => {
              return (
                <button
                  key={`${option.option}-${index}`}
                  className={`chatroom-postchat__option`}
                  onClick={() => handleSubmit(postChatQuestions[feedbackStep].question, option.option)}
                >
                  {option.option}
                </button>
              )
            })}
          </div>
          {feedbackStep + 1 <= postChatQuestions.length - 1 ?
            <p className={`chatroom-postchat__question-count`}>Q {`${feedbackStep + 1} of ${postChatQuestions.length - 1}`}</p>
          : null}
        </div>
        <div className="chatroom-postchat__footer">
          <AnimatedLink 
            to={`/support`}
            className={`chatroom-postchat__footer-link`}
            logEvent={`Postchat - Support link`}
          >
            Support us
          </AnimatedLink>
          <AnimatedLink 
            to={`/help`}
            className={`chatroom-postchat__footer-link`}
            logEvent={`Postchat - Help link`}
          >
            Help & FAQ
          </AnimatedLink>
          <button 
            onClick={() => {
              chatHelpers.leaveRoom(dispatch)
              firebase.analytics().logEvent('Postchat - New chat link');
            }}
            className={`chatroom-postchat__footer-link`}
          >
            Start a new chat
          </button>
        </div>
      </div>
    </>
  )
}

const postChatData = () => {  
  const { data } = useStaticQuery(graphql`
    query {
      data: datoCmsChatroom {
        postChatQuestions {
          question
          options {
            option
          }
        }
      }
    }
  `)
  return data
}