import React, { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import Twemoji from 'react-twemoji';
import SEO from '../layouts/seo';
import * as chatHelpers from '../../helpers/chatroom-helper'
import ChatroomCallback from './chatroom-callback';
import ChatroomPostchat from './chatroom-postchat';

export default function ChatroomBody() {
  const dispatch = useDispatch();
  const chatroomState = useSelector(state => state.chatroomState);
  const inputRef = useRef(null);
  const bodyRef = useRef(null);
  const uppercaseOtherType = chatHelpers.otherType().charAt(0).toUpperCase() + chatHelpers.otherType().slice(1);
  const { roomData } = chatroomState;
  const {active, open, listener, talker} = roomData;
  const [callbackActive, setCallbackActive] = useState(true);
  const [typingActive, setTypingActive] = useState(false);
  const [typingMarkup, setTypingMarkup] = useState(false);
  const partnerTyping = chatroomState?.chatRowData?.[chatHelpers.otherType() + `Typing`];
  

  useEffect(() => {
    if (partnerTyping) {
      setTypingMarkup(true);
      setTimeout(() => setTypingActive(true), 100);
    } else {
      setTypingActive(false);
      setTimeout(() => setTypingMarkup(false), 400);
    }
  }, [partnerTyping])

  const connectionState = () => {
    if (active && !open) {
      return "chat-active"
    } else if (active && open) {
      return "waiting"
    } else if (!active && !open && listener && talker) {
      return "user-left"
    } else {
      return "connecting"
    }
  }
  
  function containsOnlyEmojis(text) {
    const onlyEmojis = text.replace(new RegExp('[\u0000-\u1eeff]', 'g'), '')
    const visibleChars = text.replace(new RegExp('[\n\r\s]+|( )+', 'g'), '')
    return onlyEmojis.length === visibleChars.length
  }
  
  return (
    <>
      {connectionState() == 'user-left' ? 
        <ChatroomPostchat/>
      : null}
      <div className="chatroom__body-fade"></div>
      <div 
        className={`chatroom__body ${connectionState()}`}
        id="chatroom__body"
        ref={bodyRef}  
      > 
        {connectionState() == 'waiting' || connectionState() == 'chat-active' ? 
          <ChatroomCallback 
            status={connectionState()}  
            callbackActive={callbackActive}
            connected={listener && talker}
            close={() => setCallbackActive(false)}  
          />
        : null}
        {chatroomState.chatRows.length > 0 ? chatroomState.chatRows.map((row, index) => {
          let lastRow = chatroomState.chatRows[index - 1];
          let nextRow = chatroomState.chatRows[index + 1];
          let containsEmojiOnly = containsOnlyEmojis(row.text);

          let showDetails = 
            // If 3 minutes have passed in between
            (lastRow && row.time.seconds > lastRow.time.seconds + 180) || 
            // If the next row switches user
            (nextRow && row.userType !== nextRow.userType) || 
            // If it's the first message
            lastRow == null && nextRow == null ||
            // If it's the last message and doesnt hit any of the above
            nextRow == null;

          return (
            <div 
              className={`
                chatroom__row 
                ${row.userType == chatroomState.userType ? 'user' : ''}
                ${containsEmojiOnly ? 'emoji-only' : ''}
              `} 
              key={`index-${index}`}
            >
              <Twemoji options={{ className: 'twemoji' }}>
                <p className={`chatroom__row-text`}>
                  {row.text}
                </p>
              </Twemoji>
              {showDetails ? 
                <p  className={`chatroom__row-details`}>
                  {dayjs.unix(row.time.seconds).format('LT')}
                </p>
              : null}
            </div>
          )
        }) : null}
        {typingMarkup ? 
          <div className={`chatroom__typing ${typingActive ? 'active' : ''}`}>
            <div className="chatroom__typing-wrapper">
              <div className="chatroom__typing-dot1"></div>
              <div className="chatroom__typing-dot2"></div>
              <div className="chatroom__typing-dot3"></div>
            </div>
          </div>
        : null}
      </div>
    </>
  )
}