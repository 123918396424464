import React, { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import ReactTooltip from "react-tooltip";
import Flag from '../../svg/flag.svg'
import firebase from "gatsby-plugin-firebase"
import * as chatHelpers from '../../helpers/chatroom-helper'

export default function ChatroomHeader() {
  const dispatch = useDispatch();
  const [connectionState, setConnectionState] = useState('connecting');
  const chatroomState = useSelector(state => state.chatroomState);
  const { roomData } = chatroomState;
  const {active, open, listener, talker} = roomData;
  let startupTasks = false;

  useEffect(() => {
    if (!startupTasks) {
      getConnectionState()
      startupTasks = true;
    }
  })

  const getConnectionState = () => {
    if (active && !open) {
      setConnectionState("chat-active")
    } else if (active && open) {
      setConnectionState("waiting")
    } else if (!active && !open && listener && talker) {
      setConnectionState("user-left")
    } else {
      setConnectionState("connecting")
    }
  }

  const statusMarkup = () => {
    switch(connectionState) {
      case 'connecting': 
        return <p>Connecting to the server</p>
      case 'chat-active': 
        return <p>Chat is now active</p>
      case 'waiting': 
        return <p>Waiting for a {chatHelpers.otherType()} to join the chat...</p>
      case 'user-left': 
        return <p>User has left the chat</p>
    }
  }

  return (
    <div className={`chatroom__header`}>
      <ReactTooltip/>
      <div className={`chatroom__header-status ${connectionState}`}>
        {statusMarkup()}
      </div>
      <div className="chatroom__header-right">
        <button 
          data-tip="Flag this conversation"
          data-place={`bottom`}
          onClick={() => {
            chatHelpers.leaveRoom(dispatch)
            firebase.analytics().logEvent('Chatroom - Flag button clicked');
          }}
          className={`chatroom__header-flag ${connectionState}`}
        >
          <Flag/>
        </button>
        <button 
          onClick={() => {
            chatHelpers.leaveRoom(dispatch)
            firebase.analytics().logEvent('Chatroom - Leave chat button clicked');
          }}
          className={`chatroom__header-leave cta`}
        >
          Leave Chat
        </button>
      </div>
    </div>
  )
}